import Link from 'next/link'

import { NavItem } from '@/components/layout/nav-item'
import { cn } from '@/lib/utils/utils'
import { getLocalizedUri, I18nValue, useI18nValue } from '../i18n/lang-context'
import { SubMenu } from '../jbda/sub-menu'
import { Button } from '../ui/button'

export function Nav({ navItems = [], className = '' }: { navItems?: NavItem[], className?: string }) {

  const i18nValue = useI18nValue()

  const getTitle = (title: string | I18nValue) => typeof title === 'string' ? title : i18nValue(title)

  return (
    <nav className={cn('flex h-full items-center gap-0 xl:gap-4', className)}>
      {navItems?.map((item, index) => item.href && (
        <Link
          key={index}
          href={getLocalizedUri(item.href)}
          className='flex items-center'
        >
          <Button variant={'link'} className={cn(
            'text-sm font-medium text-foreground opacity-75 lg:text-[12px] xl:text-[14px]'
          )}>{typeof item.title === 'string' ? item.title : i18nValue(item.title)}</Button>
        </Link>
      ) || item.subs && item.subs.length > 0 && (
        <SubMenu title={getTitle(item.title)} navItems={item.subs} key={index} />
      ))}
    </nav>
  )
}
