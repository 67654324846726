'use client'

import { cn } from '@/lib/utils/utils'
import Link from 'next/link'
import { useState } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { getLocalizedUri, useI18nValue } from '../i18n/lang-context'
import { NavItem } from '../layout/nav-item'
import { Button } from '../ui/button'
import { Flex, FlexCol } from '../ui/flex'

export function SubMenu({ title, navItems }: { title: string, navItems: NavItem[] }) {

  const [expanded, setExpanded] = useState(false)

  const i18nValue = useI18nValue()

  return (
    <div className='relative select-none' onClick={() => setExpanded(exp => !exp)}>
      <Flex className='cursor-pointer items-center gap-0 text-sm transition-all'>

        <Button variant={'link'} className={cn(
          'text-sm font-medium text-foreground opacity-75 lg:text-[12px] xl:text-[14px]'
        )}>
          {title}
          <div id='ARROW' className='text-lg font-semibold opacity-70'>
            <MdKeyboardArrowDown size={16} className={cn('transition-all', expanded ? 'rotate-180' : '')} />
          </div>
        </Button>

      </Flex>
      {expanded && <div className='fixed left-0 top-0 z-10 h-[100vh] w-[100vw] bg-transparent'/>}
      {typeof window !== 'undefined' && <FlexCol className={cn('absolute -left-14 top-full z-20 mt-2 w-fit origin-top items-center gap-0 overflow-hidden rounded-md bg-white/90 p-1 text-sm shadow-sm transition-all dark:bg-gray-800/90 dark:shadow-header', expanded ? 'scale-y-100' : 'scale-y-0')}>
        {navItems.map((item: NavItem, index: number) => item.href && (
          <Link
            key={index}
            href={getLocalizedUri(item.href)}
            className='flex items-center'
          >
            <Button variant={'link'} className={cn(
              'text-sm font-medium text-foreground opacity-75 lg:text-[12px] xl:text-[14px]'
            )}>{typeof item.title === 'string' ? item.title : i18nValue(item.title)}</Button>
          </Link>
        ))}
      </FlexCol>
      }
    </div>
  )
}
