'use client'

import { useTheme } from 'next-themes'
import { useEffect, useState } from 'react'
import { RiMoonLine, RiSunLine } from 'react-icons/ri'

export function ThemeToggle() {

  const { setTheme, theme } = useTheme()
  const [mounted, setMounted] = useState(false)
  useEffect(() => {
    setMounted(true)
  }, [])
  if (!mounted) return null

  return (<>
    <div className='block cursor-pointer opacity-30 transition-all hover:opacity-100 dark:hidden' onClick={() => setTheme('dark')}>
      <RiSunLine size={16} />
    </div>
    <div className='hidden cursor-pointer opacity-30 transition-all hover:opacity-100 dark:block' onClick={() => setTheme('light')}>
      <RiMoonLine size={16} />
    </div>
  </>)
}
