'use client'

import { cn } from '@/lib/utils/utils'
import Link from 'next/link'
import { usePathname, useRouter } from 'next/navigation'
import { useEffect, useState } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/md'
import { Flex, FlexCol } from '../ui/flex'
import { getLangCode, getLocalizedUri, LangCode, LANGS, setLangCode } from './lang-context'

export function LangToggle() {

  const [currentLang, setCurrentLang] = useState('')
  const router = useRouter()
  const pathname = usePathname()

  useEffect(() => {
    setCurrentLang(getLangCode())
  }, [])

  const changeLocale = () => {
    const locales = Object.keys(LANGS).join('|')
    const localeRegex = new RegExp(`^/(${locales})`)
    const pathUri = pathname.replace(localeRegex, '')
    router.push(getLocalizedUri(pathUri))
  }

  const pickLang = (newLangCode: LangCode) => {
    setLangCode(newLangCode)
    setCurrentLang(getLangCode())
    changeLocale()
  }

  return (<div
    className='cursor-pointer text-sm opacity-40 transition-all hover:opacity-100'
    onClick={() => pickLang(getLangCode() === 'fr' ? 'en' : 'fr')}>
    {(getLangCode() === 'fr' ? 'en' : 'fr').toUpperCase()}
  </div>)
}

export function LangMenu() {

  const [expanded, setExpanded] = useState(false)
  const router = useRouter()
  const pathname = usePathname()

  const changeLocale = () => {
    const locales = Object.keys(LANGS).join('|')
    const localeRegex = new RegExp(`^/(${locales})`)
    const pathUri = pathname.replace(localeRegex, '')
    router.push(getLocalizedUri(pathUri))
  }

  const pickLang = (newLangCode: LangCode) => {
    setLangCode(newLangCode)
    changeLocale()
  }

  return (
    <div className='relative select-none' onClick={() => setExpanded(exp => !exp)}>
      <Flex className='cursor-pointer items-center gap-0 text-sm opacity-40 transition-all hover:opacity-100'>
        {getLangCode().toUpperCase()}
        <div id='ARROW' className='text-lg font-semibold opacity-70'>
          <MdKeyboardArrowDown size={16} className={cn('transition-all', expanded ? 'rotate-180' : '')} />
        </div>
      </Flex>
      {expanded && <div className='fixed left-0 top-0 z-10 h-[100vh] w-[100vw] bg-transparent'/>}
      {typeof window !== 'undefined' && <FlexCol className={cn('absolute left-[-25%] top-full z-20 mt-2 w-fit origin-top items-center gap-0 overflow-hidden rounded-md bg-white/90 p-1 text-sm shadow-sm transition-all dark:bg-gray-800/90 dark:shadow-header', expanded ? 'scale-y-100' : 'scale-y-0')}>
        <div className={cn('p-1 transition-all hover:opacity-100', getLangCode() === 'fr' ? 'font-medium' : 'cursor-pointer font-normal opacity-40')} onClick={() => pickLang('fr')}>FR</div>
        <div className={cn('p-1 transition-all hover:opacity-100', getLangCode() === 'en' ? 'font-medium' : 'cursor-pointer font-normal opacity-40')} onClick={() => pickLang('en')}>EN</div>
      </FlexCol>}
    </div>
  )
}

export function LangMenuFlat({ className }: { className: string }) {

  const router = useRouter()
  const pathname = usePathname()

  const changeLocale = () => {
    const locales = Object.keys(LANGS).join('|')
    const localeRegex = new RegExp(`^/(${locales})`)
    const pathUri = pathname.replace(localeRegex, '')
    router.push(getLocalizedUri(pathUri))
  }

  const pickLang = (newLangCode: LangCode) => {
    setLangCode(newLangCode)
    changeLocale()
  }

  return (
    <div className={cn('relative select-none', className)}>
      {<Flex className={cn('w-full items-center justify-center gap-8')}>
        <div className={cn('p-1 transition-all hover:opacity-100', getLangCode() === 'fr' ? 'font-medium opacity-75' : 'cursor-pointer font-normal opacity-40')} onClick={() => pickLang('fr')}>FR</div>
        <div className={cn('p-1 transition-all hover:opacity-100', getLangCode() === 'en' ? 'font-medium opacity-75' : 'cursor-pointer font-normal opacity-40')} onClick={() => pickLang('en')}>EN</div>
      </Flex>}
    </div>
  )
}

export function LangMenuFlatHTML({ className }: { className: string }) {

  const pathname = usePathname()

  const getNewLocalizedUri = (lang?: string) => {
    const locales = Object.keys(LANGS).join('|')
    const localeRegex = new RegExp(`^/(${locales})`)
    const pathUri = pathname.replace(localeRegex, '')
    return getLocalizedUri(pathUri, lang)
  }

  return (
    <div className={cn('relative select-none', className)}>
      {<Flex className={cn('w-full items-center justify-center gap-8')}>
        <Link className={cn('p-1 transition-all hover:opacity-100', getLangCode() === 'fr' ? 'font-medium opacity-75' : 'cursor-pointer font-normal opacity-40')} href={getNewLocalizedUri('fr')}>FR</Link>
        <Link className={cn('p-1 transition-all hover:opacity-100', getLangCode() === 'en' ? 'font-medium opacity-75' : 'cursor-pointer font-normal opacity-40')} href={getNewLocalizedUri('en')}>EN</Link>
      </Flex>}
    </div>
  )
}

export default LangMenu
